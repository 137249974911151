import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  CallToActionBanner,
  OutboundLink,
  InternalLink,
  Call,
  Image,
  Background,
  ContinuousScroll,
  PatternedImageRow,
  BasicCarousel,
  componentIterator,
  Review,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      externalOrderOnlinePage,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          className={"home-page"}
          hero={
            <HeroElement
              header={""}
              tagline={""}
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              carouselOptions={{ autoScrollTime: 10000 }}
            />
          }
        >
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"welcome-to-grid"}
          >
            <Grid.Column className={"welcome-copy-column"}>
              <Content stackable={true} textAlign={"left"}>
                <Header as={"h1"} className={"welcome-to-header"}>
                  WELCOME TO {fishermanBusiness.name}
                </Header>
                <Header as={"h2"} className={"welcome-to-subheader"}>
                  DINE WITH US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={true}
                  content={fishermanBusiness.aboutMarkdown}
                />
              </Content>
              <Grid.Column width={16}>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button
                      as={OutboundLink}
                      to={externalOrderOnlinePage.url}
                      primary={true}
                    >
                      Order Online
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Button to={"/menu/"} as={InternalLink}>
                      VIEW MENU
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Call
                      phoneNumber={
                        fishermanBusiness.primaryLocation.phoneNumber
                      }
                      as={"button"}
                    />
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column className={"welcome-image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "Content.Image",
                  componentIdentifier: "welcome",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <ContinuousScroll
                  className={"patterned-images-container-scroll"}
                >
                  <PatternedImageRow
                    containerAs={ContinuousScroll.Item}
                    images={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    })}
                    imageSizePatterns={[
                      { width: 50, height: 83, alignment: "middle" },
                    ]}
                  />
                </ContinuousScroll>
              </Background.Pattern>
            </Background>
          </div>
          <div className={"reviews-container"}>
            <Header as={"h1"} textAlign={"center"}>
              CHEERS TO CARNEY’S
            </Header>
            <Header as={"h2"} textAlign={"center"} color={"yellow"}>
              REVIEWS FROM OUR LOYAL PATRONS
            </Header>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
              items={componentIterator({
                iterator: fishermanBusiness.reviews,
                component: <Review as={"horizontalItem"} charLimit={200} />,
                propMap: { author: "author", text: "text" },
              })}
            />
          </div>
          <Grid
            stackable={true}
            className={"component-section-container cards-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Row>
              <Grid.Column textAlign={"center"}>
                <Header as={"h1"} textAlign={"center"}>
                  EXPLORE CARNEY’S CLASSICS
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Column width={12} textAlign={"center"}>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Card className={"custom-card-container"}>
                      <Card.Content
                        className={"custom-card-content custom-card-1"}
                      >
                        <Card.Description className={"custom-card-description"}>
                          <div className={"custom-card-overlay"}>
                            <h1>GOOD</h1>
                            <h1>FOOD</h1>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Card className={"custom-card-container"}>
                      <Card.Content
                        className={"custom-card-content custom-card-2"}
                      >
                        <Card.Description className={"custom-card-description"}>
                          <div className={"custom-card-overlay"}>
                            <h1>BETTER</h1>
                            <h1>DRINKS</h1>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Card className={"custom-card-container"}>
                      <Card.Content
                        className={"custom-card-content custom-card-3"}
                      >
                        <Card.Description className={"custom-card-description"}>
                          <div className={"custom-card-overlay"}>
                            <h1>OUTDOOR</h1>
                            <h1>SEATING</h1>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Card className={"custom-card-container"}>
                      <Card.Content
                        className={"custom-card-content custom-card-4"}
                      >
                        <Card.Description className={"custom-card-description"}>
                          <div className={"custom-card-overlay"} />
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      primaryLocation {
        phoneNumber
      }
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
      }
    }
    externalOrderOnlinePage: fishermanBusinessWebsitePage(
      title: { eq: "Order Online" }
      pageType: { eq: "External" }
    ) {
      url
    }
  }
`;
